#SelectUser {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 1rem;

    .user {
        align-items: center;
        display: flex;
        line-height: 1.15rem;

        &--meta {
            color: grey;
            font-size: calc(15rem / 16);
        }

        &--specialty::before {
            content: " ";
        }

        &--profile-photo {
            background-color: #e2e2e2;
            border-radius: 3px;
            height: 45px;
            overflow: hidden;
            margin: 0 0.5rem 0 0;
            width: 45px;

            &--image {
                width: 100%;
            }
        }
    }
}